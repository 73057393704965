(function (window, document, $) {
   "use strict";

   var NavBar = function (element) {
      this.$navBar = $(element);
      this.$navBarBody = this.$navBar.find('[data-role="nav-body"]');
      this.$navBarDeployer = this.$navBar.find('[data-role="nav-deployer"]');
      this.$navBarDeployer.on('click.NavBar', this.toggleNavBar.bind(this));
      var offset = $(this.$navBar).data('offset');
      var stopper = $($(this.$navBar).data('stopper')).height() || $('footer').height();
      var status = true;
      var returner = true;

      // var head = $('[data-head]').height() + $('[data-sticky]').height() + 100 || 0;
      // offset = head;

      if (stopper === undefined || stopper == false) {
         status = false;
      }

      $(window).on('scroll', function () {
         
         var scroll = this.pageYOffset || document.documentElement.scrollTop;
         var scrollHeight = $(document).height();
         var scrollPosition = $(window).height() + $(window).scrollTop();
         var scrollstop = (scrollHeight - stopper);
         var stop = (scrollstop - scrollPosition) / scrollHeight;
         
         status == false ? returner = true : returner = stop > 0;

         if (scroll > offset && returner == true) {
            $('body').addClass('scrolled');
            $('body').removeClass('no-scrolled bottomed');
         } else if(scroll > offset && returner == false){
            $('body').addClass('bottomed scrolled');
            $('body').removeClass('no-scrolled');
         }else {
            $('body').addClass('no-scrolled');
            $('body').removeClass('scrolled bottomed');
         }
      });

      this.touchSubmenus();

      //WATCH
      this.$searchBarBody = $('body').find('[data-role="search-body"]');
      this.$searchBarDeployer = $('body').find('[data-role="search-deployer"]');
      this.$searchBarDeployer.on('click.NavBar', this.toggleExtra.bind(this));

      return this;
   };

   NavBar.prototype = {
      toggleNavBar: function (event) {
         event.preventDefault();
         this.$navBarDeployer.toggleClass('deployed');
         this.$navBarBody.toggleClass('deployed');
         //WATCH
         $('body').toggleClass('menu-open').removeClass('scrolled');
         this.$searchBarDeployer.removeClass('deployed');
         this.$searchBarBody.removeClass('deployed');
         $('body').removeClass('search-open');
      },

      toggleExtra: function (event) {
         event.preventDefault();
         this.$searchBarDeployer.toggleClass('deployed');
         this.$searchBarBody.toggleClass('deployed');

         if(this.$searchBarBody.hasClass('deployed')){
            $('#busqueda').focus()
         }else{
            $('#busqueda').blur()
         }
         $('body').toggleClass('search-open').removeClass('search-start');

         this.$navBarDeployer.removeClass('deployed');
         this.$navBarBody.removeClass('deployed');
         $('body').removeClass('menu-open');
      },
      touchSubmenus: function () {
         console.log('init touch');

         // if (!Modernizr.touchevents) {
         //    return;
         // }

         let $touchSubmenus = document.querySelectorAll('[data-role="touch-submenu"]');
         let $touchSubmenusDeployer = document.querySelectorAll('[data-role="touch-submenu-deployer"]');

         for (const deployer of $touchSubmenusDeployer) {
            deployer.addEventListener('click', function (event) {
               event.preventDefault();
               let $target = event.target.parentNode.closest('[data-role="touch-submenu"]');
               let $othersSubmenus = [...$touchSubmenus].filter(item => item != $target);
               $target.classList.toggle('deployed');
               for (const $otherSubmenu of $othersSubmenus) {
                  $otherSubmenu.classList.remove('deployed');
               }
            })
         }
      }
   };

   $.fn.navBar = function () {
      if (this.data('navBar')) {
         return this.data('navBar');
      }
      return this.each(function (i, el) {
         $(el).data('navBar', (new NavBar(el)));
      });
   };

   // self initialization
   $(document).ready(function () {
      $('[data-module="nav-bar"]').navBar();
   });

}(window, document, jQuery));