(function (window, document, $) {
	"use strict";
	var DEBUG = true;
	var $window = $(window),
		$document = $(document),
		$body = $('body'),
		$mainNav, $mainHeader,
		$nav = $('.nav-bar');

	var LARGE_DOWN = 'screen and (max-width: 1024px)',
		BOOK_DOWN = 'screen and (max-width: 900px)',
		MEDIUM_DOWN = 'screen and (max-width: 850px)',
		TABLET_DOWN = 'screen and (max-width: 768px)',
		SMALL_DOWN = 'screen and (max-width: 600px)',
		PHABLET_DOWN = 'screen and (max-width: 480px)',
		ZERO = 'screen and (max-width: 0px)',
		LARGE_SIZE = 1024,
		MEDIUM_SIZE = 850,
		TABLET_SIZE = 768,
		SMALL_SIZE = 600,
		PHABLET_SIZE = 480;

	var throttle = function (fn) {
		return setTimeout(fn, 1);
	};

	var mqMap = function (mq) {
		var MQ = '';

		switch (mq) {
			case 'large-down':
				MQ = LARGE_DOWN;
				break;
			case 'book-down':
				MQ = BOOK_DOWN;
				break;
			case 'medium-down':
				MQ = MEDIUM_DOWN;
				break;
			case 'tablet-down':
				MQ = TABLET_DOWN;
				break;
			case 'small-down':
				MQ = SMALL_DOWN;
				break;
			case 'phablet-down':
				MQ = PHABLET_DOWN;
				break;
		}

		return MQ;
	};

	var normalize = (function () {
		var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
			to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
			mapping = {};

		for (var i = 0, j = from.length; i < j; i++)
			mapping[from.charAt(i)] = to.charAt(i);

		return function (str) {
			var ret = [];
			for (var i = 0, j = str.length; i < j; i++) {
				var c = str.charAt(i);
				if (mapping.hasOwnProperty(str.charAt(i)))
					ret.push(mapping[c]);
				else
					ret.push(c);
			}
			return ret.join('');
		};
	})();

	//TODO: watch
	// String.prototype.capitalize = function() {
	// 	return this.charAt(0).toUpperCase() + this.substring(1).toLowerCase();
	// }

	//APP	
	var App = function () {
		this.path = $('body').attr("data-path");
		this.home = $('body').attr("data-home");
		this.ajaxURL = '/wp-admin/admin-ajax.php';
		this.loadLegacyAssets();

		var app = this;
	};

	App.prototype = {
		//TODO:
		//Starting settings
		onReady: function () {
			this.setGlobals();
			this.autoHandleEvents($('[data-func]'));
			this.initTextCounter();
			this.initAreaResizer();
			this.conditionalInits();
			this.stickyParent();
			this.datanoclass();
			this.dataaddclass();
			this.removeClassOnTablet();
			this.deviceCity();
			this.initCleanSpace();
			this.initValidFile();
			this.initSubmitOnChange();
			this.initSelector();
			this.initSelectorTarget();
			this.datatooltip();
			this.datamultifile();
			this.addClassOnScroll();
			this.stickynav();
			this.tablecontrol();
			this.removeTargetTablet();
			this.autoImportant();
			this.touchControl();
			this.datacalendar_es();

			this.ImgSourceSetInitialize();
			this.BgSourceSetInitialize();
		},
		onLoad: function () {
			var app = this;

			$('[data-equalize="children"][data-mq="large-down"]').equalizeChildrenHeights(true, LARGE_DOWN);
			$('[data-equalize="children"][data-mq="book-down"]').equalizeChildrenHeights(true, BOOK_DOWN);
			$('[data-equalize="children"][data-mq="medium-down"]').equalizeChildrenHeights(true, MEDIUM_DOWN);
			$('[data-equalize="children"][data-mq="tablet-down"]').equalizeChildrenHeights(true, TABLET_DOWN);
			$('[data-equalize="children"][data-mq="small-down"]').equalizeChildrenHeights(true, SMALL_DOWN);
			$('[data-equalize="children"][data-mq="phablet-down"]').equalizeChildrenHeights(true, PHABLET_DOWN);
			$('[data-equalize="children"][data-mq="false"]').equalizeChildrenHeights(true, ZERO);
			$('[data-equalize="target"][data-mq="large-down"]').equalizeTarget(true, LARGE_DOWN);
			$('[data-equalize="target"][data-mq="book-down"]').equalizeTarget(true, BOOK_DOWN);
			$('[data-equalize="target"][data-mq="medium-down"]').equalizeTarget(true, MEDIUM_DOWN);
			$('[data-equalize="target"][data-mq="tablet-down"]').equalizeTarget(true, TABLET_DOWN);
			$('[data-equalize="target"][data-mq="small-down"]').equalizeTarget(true, SMALL_DOWN);
			$('[data-equalize="target"][data-mq="phablet-down"]').equalizeTarget(true, PHABLET_DOWN);
			$('[data-equalize="target"][data-mq="false"]').equalizeTarget(true, ZERO);

			jQuery.each(jQuery('[data-role="scroll-navigation"]'), function () {
				app.scrollNavigation($(this));
			});

			if ($('[data-role="horizon-navigation"]').length) {
				this.horizonNavigation($('[data-role="horizon-navigation"]'));
			}

			if ($('[data-role="scroll-navigation-sticky"]').length) {
				this.scrollFixed($('[data-role="scroll-navigation-sticky"]'));
			}

			jQuery.each($('[data-role="touch-submenu"]:not(.current-menu-item)'), function () {
				$(this).on('mouseover', function () {
					$('[data-role="touch-submenu"].menu-item-has-children.no-touch').addClass('cancel-current-menu-item');
					$(this).removeClass('cancel-current-menu-item');
				}).mouseleave(function () {
					$('[data-role="touch-submenu"].menu-item-has-children.no-touch').removeClass('cancel-current-menu-item');
				});
			});

			$window.trigger('resize');
		},

		onResize: function () {
			var app = this;
			throttle(function () {
				// app.touchControl();
			});
		},

		onScroll: function () {
			console.log('scrolling');
		},

		loadLegacyAssets: function () {
			// voy a asumir que cualquier browser que no soporte <canvas> es un oldIE (IE8-)
			if (Modernizr.canvas) {
				return false;
			}
			Modernizr.load({
				load: this.path + 'scripts/support/selectivizr-1.0.2.min.js'
			});
		},

		autoHandleEvents: function ($elements) {
			if (!$elements || !$elements.length) {
				return false;
			}
			var self = this;
			$elements.each(function (i, el) {
				var func = el.getAttribute('data-func') || false,
					evts = el.getAttribute('data-events') || 'click.customStuff';
				if (func && typeof (self[func]) === 'function') {
					$(el)
						.off(evts)
						.on(evts, $.proxy(self[func], self));
				}
			});
		},

		setEnquire: function () {
			var app = this,
				$mutable = $('[data-mutable]');

			enquire.register(LARGE_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="large-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="large-down"]'), 'desktop');
				}
			}]);

			enquire.register(BOOK_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="book-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="book-down"]'), 'desktop');
				}
			}]);

			enquire.register(TABLET_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="tablet-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="tablet-down"]'), 'desktop');
				}
			}]);

			enquire.register(MEDIUM_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="medium-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="medium-down"]'), 'desktop');
				}
			}]);

			enquire.register(SMALL_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="small-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="small-down"]'), 'desktop');
				}
			}]);

			enquire.register(PHABLET_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="phablet-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="phablet-down"]'), 'desktop');
					$('[data-role="nav-body"]').removeClass('deployed').removeAttr('style');
				}
			}]);

			// para qeu todo funcione bien en movil
			// se gatilla el evento resize en window
			$window.trigger('resize');
		},

		conditionalInits: function ($context) {
			if (!$context) {
				$context = $document;
			}
			// delegaciones directas
			if ($context.find('[data-func]').length) {
				this.autoHandleEvents($context.find('[data-func]'));
			}

			if ($('[data-func="stickyheader"]').length) {
				this.stickyheader($('[data-func="stickyheader"]'));
			}

			if ($('[data-role="videothumb"]').length) {
				this.videohide($('[data-role="videothumb"]'));
			}

			// si no reconoces matchmedia no mereces enquire
			if (window.matchMedia) {
				this.setEnquire();
			}

		},

		setGlobals: function () {
			$body = $('body');
			$mainHeader = $('#main-header');
			$mainNav = $('#main-nav');
			if ($mainNav.length) {
				this.navPos = $mainNav.offset().top;
			}
		},

		debug: function (message) {
			DEBUG && console.log(message);
		},

		moveElements: function ($set, type) {
			var areaType = 'data-' + type + '-area',
				groups = $set.groupByAtt(areaType);

			groups.forEach(function ($group) {
				var $target = $('[data-area-name="' + $group.first().attr(areaType) + '"]');

				$group.sort(function (a, b) {
					return $(a).data('order') - $(b).data('order');
				});

				$group.appendTo($target);
			});
		},

		//FUNC:

		mapSectionsPositions: function ($items) {
			var map = [];
			$items.each(function (index, el) {
				var $el = $(el),
					$target = $($el.attr('href')),
					targetOffset = $target.offset();

				targetOffset.bottom = targetOffset.top + $target.height();

				map.push({
					$item: $el,
					offset: targetOffset,
					selector: $el.attr('href')
				});
			});

			return map;
		},

		listSectionsPositions: function ($items) {
			var map = [];
			$items.each(function (index, el) {
				var $el = $(el);

				if ($el.children().attr('href') !== '' && $el.children().attr('href') !== '#') {
					var $target = $($el.children().attr('href')),
						targetOffset = $target.offset();

					if (typeof ($target.val()) !== 'undefined') {

						targetOffset.bottom = targetOffset.top + $target.height();

						map.push({
							$item: $el,
							offset: targetOffset,
							selector: $el.children().attr('href')
						});
					}
				}
			});

			return map;
		},

		listSectionsPositionsData: function ($items) {
			var map = [];
			$items.each(function (index, el) {
				var $el = $(el),
					$target = $($el.children().attr('data-target')),
					targetOffset = $target.offset();

				if (typeof ($target.val()) !== 'undefined') {

					targetOffset.bottom = targetOffset.top + $target.height();

					map.push({
						$item: $el,
						offset: targetOffset,
						selector: $el.children().attr('data-target')
					});
				}
			});

			return map;
		},

		setFixedHeader: function () {
			if (Modernizr.mq(VERTICAL_TABLETS_DOWN)) {
				var headerHeight = document.querySelector('#main-header').offsetHeight;
				document.body.style.marginTop = headerHeight + 14 + 'px';
			} else {
				document.body.style.marginTop = 0;
			}
		},

		blockScroll: function (event) {
			event.stopPropagation();
		},

		scrollNavigation: function ($nav) {
			var app = this,
				$navItems = $nav.children(),
				locationsMap = this.listSectionsPositions($navItems);

			$window.on('scroll.ScrollNav', function () {
				locationsMap = app.listSectionsPositions($navItems);
				var scrollPosition = $window.scrollTop();

				locationsMap.forEach(function (item_info) {
					var horizon_href = item_info.$item.find('a').attr('href'),
						scrolltop = item_info.offset.top;

					if ($(horizon_href)) {
						var $horizon = $(horizon_href);
						var calculo = (scrolltop + 100) + $horizon.height();

						if (scrollPosition > (scrolltop - 100)) {
							$navItems.removeClass('active');
							item_info.$item.addClass('active');
							item_info.$item.trigger('click');
						} else if (scrollPosition < (scrolltop - 100)) {
							item_info.$item.removeClass('active');
						} else {
							item_info.$item.removeClass('active');
						}
					} else {
						item_info.$item.removeClass('active');
					}
				});
			});
		},

		horizonsPositions: function ($items) {
			var map = [];
			$items.each(function (index, el) {
				var $el = $(el),
					$target = $('#' + $el.attr('id')),
					targetOffset = $el.offset();

				targetOffset.bottom = targetOffset.top + $target.height();

				map.push({
					$item: $el,
					offset: targetOffset,
					selector: $el.attr('id')
				});
			});

			return map;
		},

		horizonNavigation: function ($main) {
			var app = this,
				$horizons = $main.children('section'),
				pollPosition = this.horizonsPositions($horizons);


			$window.on('scroll', function () {
				var scrollPosition = $window.scrollTop();
				pollPosition.forEach(function (item_info) {
					var $this = $('#' + item_info.selector),
						$offset = $this.data('offset') || 700;

					if (scrollPosition > (item_info.offset.top - $offset)) {
						var display = $window.width();
						$horizons.removeClass('active');
						item_info.$item.addClass('active');
						item_info.$item.addClass('visited').addClass('swaped').removeClass('wait');

						if (!$this.prev().hasClass('listening')) {
							$this.prev().addClass('visited');
						}

						if ($this.is(':last-child') || display <= TABLET_SIZE) {
							item_info.$item.addClass('visited').addClass('swaped').removeClass('wait');
						}

						if (!item_info.$item.hasClass('visited')) {
							item_info.$item.removeClass('wait');
						}
					}
				});
			});
		},

		calendarControl: function (event) {
			event.preventDefault();

			var app = this;

			var $btn = $(event.currentTarget),
				$dataHolder = $btn.parents('[data-role="calendar-data"]'),
				$itemsHolder = $('[data-events-holder]'),
				$monthName = $('[data-role="calendar-month"]'),
				direction = $btn.data('direction'),
				month = $dataHolder.data('month'),
				year = $dataHolder.data('year'),
				filter = $dataHolder.data('filter');

			if (app.isLoading) {
				return;
			}

			app.isLoading = true;

			/// se debe indicar que se esta cargando, asumo estados estandar
			/// eso queire decir, opacity nomas
			$monthName.css('opacity', '0.2');
			$itemsHolder.css('opacity', '0.2');

			$.getJSON('/wp-json/st-rest/actividades', {
				direction: direction,
				month: month,
				year: year,
				filter: filter
			}).then(function (response) {
				$dataHolder.data('month', response.month_num);
				$dataHolder.data('year', response.year);

				$monthName
					.text(response.month_name + ' - ' + response.year)
					.attr({
						'data-prev': response.prev,
						'data-next': response.next
					});

				$itemsHolder.html(response.items);

				$monthName.css('opacity', '1');
				$itemsHolder.css('opacity', '1');

				app.isLoading = false;
			});
		},


		deployTarget: function (event) {
			event.preventDefault();

			var $item = $(event.currentTarget),
				target = $item.data('target'),
				$targetElem;

			if (!target) {
				console.warn('No se especificó un objetivo en el atributo "data-target":', target);
				return;
			}

			$targetElem = $(target);
			if (!$targetElem.length) {
				console.warn('El objetivo no fue encontrado o el atributo "data-target" no es un selector válido :', target);
				return;
			}

			$item.data('animating', true);

			if ($item.data('deployed')) {
				$targetElem
					.slideUp().promise()
					.then(function () {
						$item.data({
							deployed: false,
							animating: false
						}).removeClass('deployed');

						$targetElem.removeClass('deployed');
					});
			} else {
				$targetElem
					.slideDown().promise()
					.then(function () {
						$item.data({
							deployed: true,
							animating: false
						}).addClass('deployed');

						$targetElem.addClass('deployed');
					});
			}
		},

		deployMainNav: function (event) {
			event.preventDefault();

			var $btn = $(event.currentTarget),
				$nav = $('[data-role="nav-container"]'),
				$headerBody = $('[data-role="header-body"]');

			if ($btn.data('deployed')) {
				$btn
					.data('deployed', false)
					.removeClass('deployed');

				$nav.removeClass('deployed');
				$headerBody.removeClass('deployed');
			} else {
				$btn
					.data('deployed', true)
					.addClass('deployed');

				$nav.addClass('deployed');
				$headerBody.addClass('deployed');
			}
		},

		deployCollapsable: function (event) {
			event.preventDefault();

			var $item = $(event.currentTarget),
				$targetElem = $item.parents('.collapsable').find('.collapsable-body');

			$item.data('animating', true);

			if ($item.data('deployed')) {
				$targetElem
					.slideUp().promise()
					.then(function () {
						$item.data({
							deployed: false,
							animating: false
						}).removeClass('deployed');

						$targetElem.removeClass('deployed');
					});
			} else {
				$targetElem
					.slideDown().promise()
					.then(function () {
						$item.data({
							deployed: true,
							animating: false
						}).addClass('deployed');

						$targetElem.addClass('deployed');
					});
			}
		},

		toggleTarget: function (event) {
			event.preventDefault();

			$(event.currentTarget.getAttribute('data-target')).toggleClass('deployed');

			// expansion para cuando quiero enfocar algo despues de mostrarlo
			if (event.currentTarget.getAttribute('data-focus')) {
				$(event.currentTarget.getAttribute('data-focus')).focus();
			}
		},

		toggleTargetClass: function (event) {
			event.preventDefault();

			$(event.currentTarget.getAttribute('data-target-class')).toggleClass('deployed');

			// expansion para cuando quiero enfocar algo despues de mostrarlo
			if (event.currentTarget.getAttribute('data-focus')) {
				$(event.currentTarget.getAttribute('data-focus')).focus();
			}
		},

		tabControl: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$siblings = $($button.parents('[data-role="tab-parent"]').find('[data-func="tabControl"]')),
				$targets = $($button.parents('[data-role="tab-parent"]').find('[data-tab-name]')),
				$target = $($button.parents('[data-role="tab-parent"]').find('[data-tab-name="' + $button.data('target') + '"]'));

			$siblings.removeClass('deployed');
			$targets.removeClass('deployed');

			throttle(function () {
				$button.addClass('deployed');
				$target.addClass('deployed');
			});
		},

		deployParent: function (event) {
			event.preventDefault();
			$(event.currentTarget).parents(event.currentTarget.getAttribute('data-parent')).toggleClass('deployed');
		},

		collapseControl: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$target = $('[data-target-name="' + $button.data('target') + '"]');

			throttle(function () {
				$button.toggleClass('active deployed');
				$target.toggleClass('active deployed');
			});
		},

		collapseFilter: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$target = $('[data-target-name="' + $button.data('target') + '"]');

			throttle(function () {
				$('.nav-bar').toggleClass('less-zindex')
				$body.toggleClass('filter-open');
				$button.toggleClass('active');
				$target.toggleClass('active');
			});
		},

		closeParent: function (event) {
			event.preventDefault();

			var $this = $(event.currentTarget);
			var $buttons = $('[data-target]');
			var $targets = $('[data-target-name]');
			var $nav = $('.nav-bar');

			$body.removeClass('filter-open')
			$nav.removeClass('less-zindex');
			$buttons.removeClass('active');
			$targets.removeClass('active');

			console.log($targets);
			console.log('watchout');
		},


		collapseSibling: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget);
			var button_class = $button.data('class') || 'active';
			throttle(function () {
				$button.toggleClass(button_class).next().toggleClass(button_class).parent().toggleClass(button_class);

				var $therest = $($button.parent()).siblings();

				$therest.each(function (index, element) {
					var $thisone = $(element);
					$thisone.removeClass(button_class).children().removeClass(button_class);
				});
			});


		},

		showTab: function (event) {
			event.preventDefault();
			var $item = $(event.currentTarget);

			$('[data-tabname="' + $item.data('target') + '"]').addClass('active').siblings().removeClass('active');
			$item.addClass('active').siblings().removeClass('active');
		},

		deployMobileSearch: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$searchBox = $('#mobile-search-holder');

			$button.toggleClass('deployed');
			$searchBox.toggleClass('deployed');
		},

		printPage: function (event) {
			event.preventDefault();
			window.print();
		},

		goToTop: function (event) {
			event.preventDefault();
			$('html, body').animate({
				scrollTop: 0
			}, 800);
		},


		//HELPERS

		stickyheader: function ($element) {
			var $navBar = $element,
				$top = $navBar.data('offset');

			$window.scroll(function () {
				var scroll = actualposition();
				if (scroll >= $top) {
					$navBar.addClass('sticker');
				} else {
					$navBar.removeClass('sticker');
				}
			});
		},

		videohide: function ($element) {
			var $video = $element,
				$hiddenvideos = $('[data-role="videothumb"]:hidden'),
				$container = $('[data-module="videohide"]'),
				$showatstart = $container.data('qant'),
				$btnhide = $('[data-role="hidevideos"]'),
				$btnshow = $('[data-role="loadvideos"]'),
				$total = $video.length;

			console.log('show');
			console.log($showatstart);

			$video.slice(0, $showatstart).show();
			$('[data-role="hidevideos"]').hide();

			$btnshow.click(function (e) {
				e.preventDefault();
				$hiddenvideos.show('slow');
				$(this).hide();
				$btnhide.show();
			});

			$btnhide.click(function (e) {
				e.preventDefault();
				$video.slice($showatstart, $total).hide('slow');
				$(this).hide();
				$btnshow.show();
			});
		},

		//SELF INIT

		//data-role="textcounter"
		//data-role="countdown"
		initTextCounter: function () {
			$('[data-textcounter]').keyup(function (e) {
				var $textarea = $(this),
					maxlength = parseInt($textarea.attr('maxlength')),
					valuelength = $textarea.val().length,
					countdown = $textarea.parent().find('[data-role="countdown"]');

				e.preventDefault();
				countdown.text(maxlength - valuelength);

			});
		},

		initAreaResizer: function () {
			jQuery.each(jQuery('textarea[data-autoresize]'), function () {
				var offset = this.offsetHeight - this.clientHeight;
				var resizeTextarea = function (el) {
					jQuery(el).css('height', 'auto').css('height', el.scrollHeight + offset);
				};
				jQuery(this).on('keyup input', function () {
					resizeTextarea(this);
				}).removeAttr('data-autoresize');
			});
		},

		removeTargetTablet: function () {
			jQuery.each(jQuery('[data-role="remove-attr-tablet"]'), function () {
				var $this = $(this);
				var $target = $($this.data('target'));
				var $attrtarget = $this.data('target').replace(/[\[\]']+/g, '');
				var display = $window.width();

				$(window).on('resize', function () {
					if ($(this).width() != display) {
						display = $(this).width();
					}
				});

				if (display <= TABLET_SIZE) {
					$target.removeAttr($attrtarget);
				} else {
					$target.attr($attrtarget, true);
				}
			});
		},

		autoImportant: function () {
			jQuery.each(jQuery('[data-eq-start]'), function () {
				var $element = $(this);
				var startpoint = $element.data('eq-start');
				var display = $window.width();

				$(window).on('resize', function () {
					if ($(this).width() != display) {
						display = $(this).width();
					}
					if (startpoint == 'tablet-down') {
						if (display > TABLET_SIZE) {
							$element.addClass('autoheight');
						} else {
							$element.removeClass('autoheight');
						}
					}
				});

				if (startpoint == 'tablet-down') {
					if (display > TABLET_SIZE) {
						$element.addClass('autoheight');
					} else {
						$element.removeClass('autoheight');
					}
				}
			});
		},

		touchControl: function () {
			jQuery.each(jQuery('[data-touch]'), function () {
				var $this = $(this);
				var status = $this.data('touch');
				var $window = $(window);
				if ($window.width() <= 850) {
					$this.removeClass('no-touch').addClass('touch');
					$this.attr('data-touch', true);
				} else {
					$this.addClass('no-touch').removeClass('touch');
					$this.attr('data-touch', false);
				}

				$(window).on('resize', function () {
					if ($window.width() <= 850) {
						$this.removeClass('no-touch').addClass('touch');
						$this.attr('data-touch', true);
					} else {
						$this.addClass('no-touch').removeClass('touch');
						$this.attr('data-touch', false);
					}
				});
			});
		},


		initCleanSpace: function () {
			$('[data-cleanspace]').on({
				keydown: function (e) {
					if (e.which === 32) return false;
				},
				change: function () {
					this.value = this.value.replace(/\s/g, "");
				}
			});
		},

		initValidFile: function () {
			$('[data-validfile]:required').change(function (e) {
				var $input = $(this),
					filename = $(this).val(),
					$father = $input.parents('.form-control').find('.form-control--file'),
					$mother = $input.parents('.form-control'),
					maxsize = $input.data('max-size'),
					filesize = $input.get(0).files[0].size,
					$okmessage = $mother.find('.form-control__text').text(),
					$errormessage = $mother.data('error-message');

				e.preventDefault();
				console.log('filesize: ' + filesize);
				console.log('okmessage: ' + $okmessage);
				//define el limite de tamaño
				if (maxsize) {
					maxsize = maxsize;
					console.log('limit defined:' + maxsize);
				} else {
					maxsize = 100000 //100mb
					console.log('limit undefined:' + maxsize);
				}

				if (filesize > maxsize) {
					console.log('too heavy');
					$mother.find('.form-control__text').html($errormessage);
					return false;
				} else {
					console.log('just right');
					$mother.find('.form-control__text').hide();
					if (filename.substring(3, 11) == 'fakepath') {
						filename = filename.substring(12);
					}
					if (!filename) {
						filename = $father.find('.content__btn--file__desc').text();
					}
					$father.find('.content__btn--file__desc').html(filename);
					return true;
				}
			});
		},

		initSelector: function () {
			$('.form-control select').change(function (e) {
				var $input = $(this);

				e.preventDefault();

				$input.addClass('selected');
			});
		},

		initSelectorTarget: function () {
			$('[data-role="show-on-select"]').change(function (e) {
				var $input = $(this);
				var valor = $(this).val();
				var $target = $('[data-type="' + valor + '"]');
				var $targets = $('[data-type]');
				e.preventDefault();

				$targets.removeClass('selected');
				$target.addClass('selected');
			});

			$('[data-type]').keyup(function (e) {
				var $waiter = $('[data-role="wait-for-select"]');

				$waiter.removeAttr('disabled').attr('value', '123456').addClass('blocked').addClass('valid-input');

				// $waiter.attr('disabled', true).attr('placeholder', 'Por definir').attr('value', '').removeClass('blocked');
			});
		},

		initSubmitOnChange: function () {
			$('[data-onchange]').change(function (e) {
				var $input = $(this),
					$form = $input.parents('form');

				e.preventDefault();
				$form.submit();
			});
		},

		copyClipboard: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$target = $('[data-target-name="' + $button.data('target') + '"]'),
				$value = $target.val(),
				$textArea = document.createElement("textarea"),
				$okbtn = $('.copyboard-status'),
				$isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);

			$textArea.value = $value;
			document.body.appendChild($textArea);

			if ($isiOSDevice) {
				var editable = $textArea.contentEditable;
				var readOnly = $textArea.readOnly;

				$textArea.contentEditable = true;
				$textArea.readOnly = false;

				var range = document.createRange();
				range.selectNodeContents($textArea);

				var selection = window.getSelection();
				selection.removeAllRanges();
				selection.addRange(range);

				$textArea.setSelectionRange(0, 999999);
				$textArea.contentEditable = editable;
				$textArea.readOnly = readOnly;
			} else {
				$textArea.select();
			}

			if (document.execCommand('copy')) {
				$okbtn.fadeIn(1500).addClass('ok');
				setTimeout(function () {
					$okbtn.fadeOut('slow').addClass('ok');
				}, 1500);
			} else {
				$okbtn.fadeIn(1500).addClass('error');
				setTimeout(function () {
					$okbtn.fadeOut('slow').removeClass('error');
				}, 1500);
			}

			document.body.removeChild($textArea);
			console.log('copied');
		},

		datacalendar_es: function () {
			jQuery.each(jQuery('[data-calendar-es]'), function () {
				var $calendar = $(this);

				$.datepicker.regional['es'] = {
					closeText: 'Cerrar',
					prevText: '<Ant',
					nextText: 'Sig>',
					currentText: 'Hoy',
					monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
					monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
					dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
					dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
					dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
					weekHeader: 'Sm',
					dateFormat: 'dd/mm/yy',
					firstDay: 1,
					isRTL: false,
					showMonthAfterYear: false,
					yearSuffix: ''
				};

				$.datepicker.setDefaults($.datepicker.regional['es']);

				$calendar.datepicker({
					onSelect: function (d, i) {
						if (d !== i.lastVal) {
							$(this).change();
							$(this).attr('data-validizr-validity', 'true').addClass('valid-input').parent('.form-control').addClass('valid-control');
							$(this).removeClass('invalid-input').parent('.form-control').removeClass('invalid-control');
						}
					}
				});
			});
		},

		datacalendar_en: function () {
			jQuery.each(jQuery('[data-calendar-en]'), function () {
				var $calendar = $(this);
				$calendar.datepicker();
			});
		},

		tablecontrol: function () {
			jQuery.each(jQuery('table.tablepress'), function () {
				var $this = $(this),
					$btable_html = '<div class="single__table__scroll"><table class="tablepress">' + $this.html() + '</table></div>',
					$ftable_html = '<p class="single__table__message">Desliza para ver</p>',
					$block_html = $btable_html + $ftable_html;

				$this.replaceWith($block_html);
			});
		},

		datanoclass: function () {
			jQuery.each(jQuery('[data-no-class]'), function () {
				var $this = $(this),
					$class = $this.data('no-class');

				$this.find('.' + $class).removeClass($class)
			});
		},

		dataaddclass: function () {
			jQuery.each(jQuery('[data-add-class]'), function () {
				var $this = $(this),
					$class = $this.data('no-class'),
					$target = $this.data('target');

				$this.find($target).addClass($class);
			});
		},

		removeClassOnTablet: function () {
			jQuery.each(jQuery('[data-rmclass-tablet]'), function () {
				var $this = $(this);
				var classrm = $this.data('rmclass-tablet');

				$(window).on('resize', function () {
					if ($(this).width() <= TABLET_SIZE) {
						$this.removeClass(classrm);
					} else {
						$this.addClass(classrm);
					}
				});
			});
		},

		deviceCity: function () {
			var display = $window.width();

			$('[data-check-width]').html(display);
			$(window).on('resize', function () {
				if ($(this).width() != display) {
					display = $(this).width();
					$('[data-check-width]').html(display);
				}
			});

			if (display > TABLET_SIZE) {
				$('[data-check-mobile]').html('DESKTOP (mayor a 768px)');
				// console.log('desktop');
			} else if (display >= PHABLET_SIZE && display <= TABLET_SIZE) {
				$('[data-check-mobile]').html('TABLET (menor a 768px - mayor a 480px)');
				// console.log('tablet');
			} else if (display <= PHABLET_SIZE) {
				$('[data-check-mobile]').html('MOBILE (menor a 480px)');
				// console.log('mobile');
			}
		},

		showPass: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$target = $('[data-target-name="' + $button.data('target') + '"]'),
				status = 'text';

			if ($target.attr('type') !== 'password') {
				status = 'password'
			}

			throttle(function () {
				$button.toggleClass('active');
				$target.attr('type', status);
			});
		},

		datatooltip: function () {
			$('[data-tooltip]').hover(function (e) {
				var $this = $(this);
				e.preventDefault();
				$this.toggleClass('active');
				$this.parents('.container').toggleClass('flow');
			});
		},

		datamultifile: function () {
			jQuery.each(jQuery('[data-multifile]'), function () {
				var $this = $(this);
				$this.MultiFile({
					list: '[data-multifile-list]',
					STRING: {
						remove: '<span class="icon icon-close1"></span>'
					}
				});
			});
		},

		scrollToTarget: function (event) {
			event.preventDefault();

			var $item = $(event.currentTarget),
				target = $item.attr('href') || $item.data('target'),
				dataoffset = $item.data('offset') || 0,
				dataabsolute = $item.data('absolute') || false,
				dataoffset_mobile = $item.data('offset-mobile') || dataoffset,
				datamove = 0;

			if (!$(target).length) {
				return;
			}

			if ($window.width() < 768) {
				datamove = $(target).offset().top - (dataoffset * .75)
			} else {
				datamove = $(target).offset().top - dataoffset
			}

			if (dataabsolute) {
				datamove = 0
			}

			$('html, body').animate({
				scrollTop: datamove
			});
		},

		scrollFixed: function ($nav) {
			var app = this,
				$this = $(this),
				$navItems = $nav.children(),
				locationsMap = this.listSectionsPositions($navItems),
				$control = $('[data-control]'),
				mobile = false;

			$window.on('resize.ScrollNav', function () {
				locationsMap = app.listSectionsPositions($navItems);

				if ($window.width() <= TABLET_SIZE) {
					// offset = offset + 120;
					mobile = true;
				}
			});

			$window.on('scroll.ScrollNav', function () {
				var scrollPosition = this.pageYOffset || document.documentElement.scrollTop;

				locationsMap.forEach(function (item_info) {
					var offset = $(item_info.$item.children()).data('offset') || 0;

					if (scrollPosition >= (item_info.offset.top - offset)) {
						var $active_item = item_info.$item;
						var active_title = $active_item.data('title');
						$navItems.removeClass('active');
						$active_item.addClass('active');
						if (mobile == true) { $control.html(active_title) }
						$control.removeClass('sticked').next().removeClass('sticked').parent().removeClass('sticked');
					} else if ((scrollPosition < (locationsMap[0].offset.top - $(locationsMap[0].$item.children()).data('offset')))) {
						$control.html($control.data('control'));
						$navItems.removeClass('active');
					}
				});
			});
		},

		addClassOnScroll: function () {
			jQuery.each(jQuery('[data-stickynav]'), function () {
				var $this = $(this),
					offset = $this.data('offset') || 0,
					$stopper = $('[data-role="stopper"]') || $('footer'),
					offstop = $stopper.data('offset') || offset,
					startOffset = $this.offset().top,
					stop = $stopper.offset().top || false,
					$parent = $this.parent();

				var start = startOffset - offset;
				var stop = stop - offstop;
				$parent.attr('style', 'min-height: ' + $this.height() + 'px');


				$(window).on('scroll', function () {
					var scroll = this.pageYOffset || document.documentElement.scrollTop;
					if (scroll >= start) {
						$this.addClass('sticky-enter');
						$body.addClass('sticky-active');

						if ($(this).width() <= TABLET_SIZE) {
							$('.anchor__item.current').removeClass('active');
							$('.anchor__item.current').children().removeClass('active');
						}
					} else {
						$body.removeClass('sticky-active');
						$this.removeClass('sticky-enter');

						$('.anchor__item.current').addClass('active');
						$('.anchor__item.current').children().addClass('active');
					}

					if (scroll < start) {
						$body.removeClass('sticky-active');
						$this.removeClass('sticky-exit').removeClass('sticky-enter');
						// $parent.removeAttr('style');
					}

					if (stop !== 0) {
						if ((scroll + offset) >= stop) {
							$body.removeClass('sticky-active');
							$this.addClass('sticky-exit').removeClass('sticky-enter');
						} else {
							$this.removeClass('sticky-exit');
						}
					}
				});
			});
		},

		stickynav: function () {

			var $button = $(this),
				$target = $('[data-target-name="' + $button.data('target') + '"]');

			throttle(function () {
				$button.toggleClass('active');
				$target.toggleClass('active');
			});
		},
		stickyParent: function () {
			jQuery.each(jQuery('[data-role="sticky-parent"]'), function () {
				var $this = $(this);
				var desktop_offset = $this.data('offset-desktop') || 100;
				var mobile_offset = $this.data('offset-mobile') || 100;
				var stick_mobile = $this.data('stick-mobile') || true;

				if ($(window).width() > MEDIUM_SIZE) {
					$this.stick_in_parent({
						offset_top: parseInt(desktop_offset),
						parent: ".stick-parent", // note: we must now manually provide the parent
						spacer: ".sticky-spacer",
					});
				} else {
					$this.stick_in_parent({
						offset_top: parseInt(mobile_offset),
						parent: ".stick-parent", // note: we must now manually provide the parent
						spacer: ".sticky-spacer",
					});
				}

				$(window).on('resize', function () {

					if ($(window).width() > MEDIUM_SIZE) {
						$this.stick_in_parent({
							offset_top: parseInt(desktop_offset),
							parent: ".stick-parent", // note: we must now manually provide the parent
							spacer: ".sticky-spacer",
						});
					} else {
						$this.stick_in_parent({
							offset_top: parseInt(mobile_offset),
							parent: ".stick-parent", // note: we must now manually provide the parent
							spacer: ".sticky-spacer",
						});
					}
				});
			});
		},
		ImgSourceSetInitialize: function () {
			jQuery.each(jQuery('img[data-srcset]'), function () {
				var $this_img = $(this);
				var src_set = $this_img.data('srcset') || '';
				var window_width = $window.width();

				if (src_set !== '') {
					var sizes = src_set.split(',');
					var resize = [];
					var default_src = $this_img.attr('src');

					if (sizes.length > 0) {
						$this_img.attr('data-srcfull', default_src);
						sizes.forEach(function (value, key) {
							value = value.slice(0, -1);
							var size = value.split(' ');

							resize.push({
								size: size[1],
								src: size[0]
							})
						});
						resize.reverse();
						resize.forEach(function (fuente, key) {
							if (window_width <= fuente['size']) {
								$this_img.attr('src', fuente['src'])
							}
							if (window_width > resize[0]['size']) {
								$this_img.attr('src', default_src)
							}
						});
					}
				}
			});
		},
		BgSourceSetInitialize: function () {
			jQuery.each(jQuery('[data-srcset]:not(img)'), function () {
				var $this_figure = $(this);
				var src_set = $this_figure.data('srcset') || '';

				if (src_set !== '') {
					var sizes = src_set.split(',');
					var resize = [];
					var default_src = $this_figure.attr('style');

					if (sizes.length > 0) {
						$this_figure.attr('data-srcfull', default_src);
						sizes.forEach(function (value, key) {
							value = value.slice(0, -1);
							var size = value.split(' ');

							resize.push({
								size: size[1],
								bg: size[0]
							})
						});
						resize.reverse();
						resize.forEach(function (fuente, key) {
							var window_width = $(window).width();
							if (window_width <= fuente['size']) {
								$this_figure.attr('style', fuente['bg'])
							}
							if (window_width > resize[0]['size']) {
								$this_figure.attr('style', default_src)
							}
						});
					}
				}
			});
		}
	};

	var app = new App();
	$document.ready(function () {
		app.onReady && app.onReady();
	});
	$window.on({
		'load': function () {
			app.onLoad && app.onLoad();
		}
	});

}(window, document, jQuery));

/////////////////////////////////////////
// Plugins y APIS
/////////////////////////////////////////
(function (window, $, undefined) {
	var $window = $(window);

	// pruebas personalizadas para modernizr
	Modernizr.addTest('device', function () {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	});


	var unique = function (arr) {
		var unique = [],
			i;

		for (i = 0; i < arr.length; i++) {
			var current = arr[i];
			if (unique.indexOf(current) < 0) {
				unique.push(current);
			}
		}
		return unique;
	};

	$.fn.svgfallback = function (callback) {
		if (Modernizr.svg) {
			return false;
		}

		return this.each(function () {
			this.src = this.getAttribute('data-svgfallback');
		});
	};

	$.fn.groupByAtt = function (attname) {
		var $set = this,
			groups = [],
			posibles = [];

		// se guardan todos los posibles valores
		$set.each(function (i, el) {
			posibles.push(el.getAttribute(attname));
		});

		// se quitan los elementos duplicados dejando solo los unicos
		posibles = unique(posibles);

		// se itera sobre las posibilidades y se agrupan los elementos
		posibles.forEach(function (value) {
			groups.push($set.filter('[' + attname + '="' + value + '"]'));
		});

		return groups;
	};

	$.fn.equalizeHeights = function (dinamic, mqException) {
		var items = this,
			eq_h = function ($collection) {
				var heightArray = [];

				$collection.removeClass('height-equalized').height('auto');

				if (!mqException || !Modernizr.mq(mqException)) {
					$collection.each(function (i, e) {
						heightArray.push($(e).outerHeight());
					});
					$collection.css({
						height: Math.max.apply(Math, heightArray)
					}).addClass('height-equalized').attr('data-max-height', Math.max.apply(Math, heightArray));
				}
			};

		setTimeout(function () {
			eq_h(items);
		}, 0);

		$('window').on('resize', function () {

			setTimeout(function () {
				eq_h(items);
			}, 10);
		});
	};

	$.fn.equalizeChildrenHeights = function (dinamic, mqException) {
		return this.each(function (i, e) {
			if ($(e).parents('[data-override-eq="true"]').length) {
				return;
			}
			$(e).children().equalizeHeights(dinamic, mqException);
		});
	};

	$.fn.equalizeTarget = function (dinamic, mqException) {
		return this.each(function (index, box) {
			$(box).find($(box).data('eq-target')).equalizeHeights(dinamic, mqException);
		});
	};

	$.fn.equalizeGroup = function (attname, dinamic, mqException) {
		var groups = this.groupByAtt(attname);

		groups.forEach(function ($set) {
			$set.equalizeHeights(dinamic, mqException);
		});

		return this;
	};

	$.fn.random = function () {
		var randomIndex = Math.floor(Math.random() * this.length);
		return $(this[randomIndex]);
	};
}(this, jQuery));